.runs-header {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* Adjusted to make it full width */
  align-items: center;
  background-color: white;
  /* Visible border color, and adjusted thickness */
  padding: 0 20px 20px;
  /* Added padding-bottom of 20px */
}

.runs-container {
  width: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 25px 0;
  margin-top: 20px;
  border-radius: 12px;
  height: calc(100vh - 133px);
}

.crawlers-slector {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.crawlers-slector span {
  font-family: Lato;
  font-size: 14px;
  line-height: 1.21;
  color: #1c1d21;
}

/* Additional styles for the dropdown if needed */
