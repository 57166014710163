/* Add border styles for table cells */
.TableCell {
  border: 1px solid black;
  /* You can adjust the color and width as needed */
}

.runs-table-head {
  background-color: greenyellow;
}

.runs-table-head .MuiTableCell-head {
  font-size: 15px;
  font-weight: bold;
  color: #6d768e;
  white-space: pre;
}

.loading-spinner {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 70%;
  width: 50px;
  /* Size of the spinner */
  height: 50px;
  /* Size of the spinner */
  margin-top: -25px;
  /* Half of the height to center vertically */
  margin-left: -120px;
  /* Half of the width to center horizontally */
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spin 1s linear infinite;
}

.loading-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* This is key to positioning the child absolutely within it */
  height: 300px;
  /* Set the height you want */
  width: 70%;
  /* Set the width you want */
  margin-top: 50px;
  background: #fff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.MuiTableContainer-root::-webkit-scrollbar {
  width: 0px;
}
.MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: #6d768e;
}

.comparables-header {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: white;
  padding: 0 20px 20px;
}

.comparable-select-box {
  display: flex;
}

h2 {
  color: black;
}

.value-option {
  font-size: 15px;
  padding: 30px;
}

.comparables-container {
  width: 70%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pagination-container {
  text-align: center;
  /* Center the pagination horizontally */
  padding: 20px 0;
  /* Add some padding above and below */
  user-select: none;
  /* Prevent text selection */
  margin-right: auto;
}

.pagination-container button {
  margin: 0 5px;
  /* Add space between buttons */
  padding: 5px 10px;
  /* Add some padding inside buttons */
  background-color: #f0f0f0;
  /* A light grey background */
  border: 1px solid #ccc;
  /* A simple border */
  border-radius: 4px;
  /* Rounded corners */
  cursor: pointer;
  /* Change cursor to pointer */
  transition: background-color 0.3s, transform 0.3s;
  /* Smooth background and transform transition */
}

.pagination-container button:hover {
  background-color: #e0e0e0;
  /* Darken button on hover */
}

.pagination-container button:active {
  transform: scale(0.95);
  /* Slightly shrink buttons when clicked */
}

.pagination-container button.active {
  background-color: #007bff;
  /* Highlight the active page number */
  color: white;
  border-color: #007bff;
}

.pagination-container button.disabled {
  background-color: #e9ecef;
  color: #6c757d;
  cursor: not-allowed;
}

.dropdown-title {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.21;
  color: #1c1d21;
  margin-bottom: 5px;
}

/* Container for the dropdown with the title */
.dropdown-with-title {
  display: flex;
  flex-direction: column;
  /* max-width: 20%; */
  /* adjust the width to fit within your table */
  padding: 0 10px;
  /* adjust the spacing as needed */
  margin-bottom: 10px;
}

/* Pagination container */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

/* Each page number, previous, and next links */
.pagination li {
  margin: 0 5px;
}

/* Page number buttons */
.pagination li a {
  border: none;
  padding: 5px 10px;
  text-decoration: none;
  color: #007bff;
  cursor: pointer;
}

/* Active page number */
/* .pagination li.active a {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
} */

/* Disabled buttons like previous or next when on first or last page */
.pagination li.disabled a {
  color: grey;
  cursor: default;
}

/* Hover styles for page number buttons */
.pagination li a:hover:not(.active) {
  background-color: #ddd;
}

/* The break label, which is '...' by default */
.pagination li.break a {
  cursor: default;
}

/* Add padding to the container of pagination for better spacing */
.pagination-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 75px;
  width: 100%;
}

.pagination-controls li a {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #11182766;
  font-size: 14px;
  width: 20px;
  height: 30px;
  font-family: "Lato";
}

.pagination-controls li:first-child a,
.pagination-controls li:last-child a {
  border-radius: 6px;
  background-color: #f5f6f8;
  font-size: 22px;
  font-weight: 400;
}

.pagination li.active a {
  border: solid 1px #ececf2;
  background-color: #fff !important;
  color: #1c1d21;
  border-radius: 6px;
  font-weight: 900;
}
/* Styling for the select dropdown for rows per page */
.rows-per-page-select .react-dropdown-select {
  min-width: 100px;
  border: 1px solid #ddd;
}

/* Additional styling for the select dropdown */
.rows-per-page-select
  .react-dropdown-select
  .react-dropdown-select-dropdown-handle {
  color: #007bff;
}
.react-dropdown-select,
.css-1a1f23i-DropDown,
.css-1eka4h0-DropDown {
  border-radius: 8px !important;
  border: solid 1px #ececf2 !important;
  background-color: #fff !important;
  font-family: Lato;
  font-size: 14px;
  line-height: 1.21;
  padding: 2px 10px !important;
  color: #1c1d21;
  min-width: 200px;
}
.css-t2gfcl-DropDown {
  padding: 2px 0 !important;
}
.react-dropdown-select:hover {
  border: 1px solid #22242659 !important;
}
.css-wmy1p7-ReactDropdownSelect:focus,
.css-wmy1p7-ReactDropdownSelect:focus-within {
  box-shadow: none !important;
}
.react-dropdown-select:focus {
  box-shadow: none !important;
}
.no-data-message {
  color: #6c757d;
  /* A soft, dark gray that stands out but isn't too harsh */
  text-align: center;
  /* Center the text for better focus and alignment */
  margin-top: 20px;
  /* Add some space at the top to separate it from other elements */
  font-size: 16px;
  /* Set a readable font size, adjust based on your design */
  font-style: italic;
  /* Italicize to indicate that this is an informational message */
  padding: 10px;
  /* Add some padding for better spacing */
  border: 1px solid #ced4da;
  /* A light, subtle border to distinguish the message */
  border-radius: 5px;
  /* Slightly rounded corners for a softer look */
  background-color: #f8f9fa;
  /* A very light background to highlight the message area */
  width: 80%;
  /* Adjust the width based on your layout, or consider using max-width */
  max-width: 500px;
  /* Max-width for larger screens */
  margin-left: auto;
  /* These two margins auto settings center the block horizontally */
  margin-right: auto;
}

.refresh-button {
  padding: 13px 24px 12px 25px;
  border-radius: 8px;
  background-color: #d50032;
  font-family: Lato;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.25;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  /* transition: 0.5s ease; */
}
.css-15wwp11-MuiTableHead-root,
.runs-table-head,
.MuiTableCell-head {
  /* border-radius: 8px !important; */
  background-color: #f9fafb !important;
}

.css-15wwp11-MuiTableHead-root,
.runs-table-head,
.MuiTableCell-head:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.css-15wwp11-MuiTableHead-root,
.runs-table-head,
.MuiTableCell-head:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  box-shadow: none !important;
  max-height: 70vh !important;
}

.css-1ex1afd-MuiTableCell-root {
  border-top: 1px solid rgba(224, 224, 224, 1);
  padding: 20px !important;
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  color: #1c1d21;
}
.css-1ex1afd-MuiTableCell-root:first-child {
  border-left: 1px solid rgba(224, 224, 224, 1);
  font-weight: bold;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.css-1ex1afd-MuiTableCell-root:last-child {
  border-right: 1px solid rgba(224, 224, 224, 1);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
table {
  border-collapse: separate !important;
  border-spacing: 0 7px !important;
}
.MuiPaper-root table {
  border-collapse: collapse;
  /* border-spacing: 2px; */
  /* border-spacing: 0 5px; */
}
/* Existing styles... */
thead .css-y8ay40-MuiTableCell-root {
  border: none !important;
}
thead::after {
  content: "";
  display: block;
  height: 6px;
  width: 100%;
  background: white;
}
@media (max-width: 768px) {
  .comparables-header {
    flex-direction: column;
  }

  .dropdown-with-title {
    width: 100%;
    max-width: none;
    padding: 10px 0;
    /* Add padding for spacing */
  }

  .pagination-controls {
    flex-direction: column;
  }

  .loading-spinner {
    left: 50%;
    /* Center spinner */
    margin-left: -25px;
    /* Half of the spinner size */
  }
}

/* Additional styles... */

.refresh-button:hover {
  background: #9f0025;
  /* transition: 0.5s ease-out; */
}

.react-dropdown-select-item-selected {
  color: #111827 !important;
  background-color: #ffe6ed !important;
  font-weight: bold !important;
  border-radius: 0px !important;
}

/* span.react-dropdown-select-item:hover,
span.react-dropdown-select-item:focus {
  background: rgb(255 110 148 / 17%);
} */
.css-z2hyoe-ItemComponent:hover,
.css-z2hyoe-ItemComponent:focus {
  background-color: #ffe6ed !important;
  border-radius: 0px;
}
.pagination-controls li:hover a {
  border-radius: 6px !important;
}

th,
tr,
td {
  font-family: "Lato" !important;
}

span.change-clr {
  padding: 6px 12px 7px 13px;
  border-radius: 17px;
  background-color: #7ce7ac85;
  font-family: Lato;
  font-size: 11px;
  font-weight: 900;
  color: #2e7b51;
  text-transform: uppercase;
}

span.clr-red {
  padding: 6px 12px 7px 13px;
  border-radius: 17px;
  background-color: rgba(255, 0, 0, 0.22);
  font-family: Lato;
  font-size: 11px;
  font-weight: 900;
  color: #d50032;
  text-transform: uppercase;
}

span.review-clr {
  padding: 7px 12px 8px 13px;
  border-radius: 17px;
  background-color: #f5f6f8;
  font-family: Lato;
  font-size: 14px;
  font-weight: 900;
  color: #111827;
  text-align: center;
}

input.react-dropdown-select-input {
  font-family: "Lato";
  /* font-weight: bold; */
  color: #000000 !important;
}

input.react-dropdown-select-input::placeholder {
  color: #000000;
  font-family: "Lato" !important;
  font-size: 14px;
}

.pagination li.next:hover a,
.pagination li.previous:hover a {
  background: #d50032;
  color: #ffffff;
  cursor: pointer;
}
[aria-disabled="true"] {
  background: #f5f6f8 !important;
  cursor: default !important;
}

.pagination li.next:hover:not(.disabled) a svg path,
.pagination li.previous:hover:not(.disabled) a svg path {
  stroke: #fff !important;
}
.pagination li.disabled a svg path,
.pagination li.disabled a svg path {
  stroke: #8181a566 !important;
}
.pagination li.next:active a,
.pagination li.previous:active a {
  background: #d50032;
  color: #ffffff;
}

.pagination li a {
  font-weight: bold;
}

input[type="checkbox"] {
  filter: sepia(160%) brightness(100%) hue-rotate(300deg) saturate(90%)
    contrast(400%);
  height: 17px !important;
  width: 100%;
}

th,
td {
  border-color: #ececf2 !important;
}
.react-dropdown-select-dropdown,
.react-dropdown-select-dropdown-position-bottom
  .css-1eka4h0-DropDown
  .css-13azdff-DropDown {
  max-height: 195px !important;
  border-radius: 8px !important;
  border: 1px solid #ececf2 !important;
  box-shadow: unset !important;
  overflow: auto;
}

.react-dropdown-select-dropdown,
.react-dropdown-select-dropdown-position-bottom
  .css-1eka4h0-DropDown.css-13azdff-DropDown::-webkit-scrollbar {
  display: none;
}

.react-dropdown-select-dropdown,
.react-dropdown-select-dropdown-position-bottom
  .css-1eka4h0-DropDown.css-13azdff-DropDown {
  scrollbar-width: none;
}

.css-13azdff-DropDown {
  -ms-overflow-style: none;
}
.css-13azdff-DropDown span {
  text-align: left !important;
  white-space: pre;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  line-height: 1.21;
  border-radius: 8px;
  font-weight: normal;
  color: #1c1d21;
  padding: 6px;
}
.css-13azdff-DropDown span:hover {
  background-color: #ffe6ed !important;
}
.react-dropdown-select,
.css-1a1f23i-DropDown {
  text-align: left !important;
}
.logincontainer .form input:hover {
  border: 1px solid #11182780 !important;
  outline: none;
}
.logincontainer .form input:focus {
  border: 1px solid #111827 !important;
  outline: none;
}
.disabled-button {
  background-color: yellow;
}
/* .css-wmy1p7-ReactDropdownSelect{
  display: block !important;
}
.css-jznujr-ContentComponent{
display: inline !important;
}
.css-1yc4zyy-DropdownHandleComponent,.css-1aarvou-DropdownHandleComponent{
  display: inline-block !important;
} */
.comparable-select-box .dropdown-with-title span {
  white-space: pre;
}
@media only screen and (max-width: 1450px) {
  .comparable-select-box .dropdown-with-title {
    padding: 0 5px !important;
    min-width: 180px !important;
  }
  .react-dropdown-select {
    min-width: 180px !important;
  }
 
 
}
@media only screen and (max-width: 1350px) {
  .comparable-select-box .dropdown-with-title {
    padding: 0 3px !important;
  }
  
}

.questionsmarkspace{
  margin-left: 6px;
  position: relative;
  top: 3px;
  cursor: pointer;
}