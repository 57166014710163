.comparables-header {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: white;
  padding: 0 20px 20px;
}

.comparable-select-box {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

h2 {
  color: black;
}

.value-option {
  font-size: 15px;
  padding: 30px;
}

.comparables-container {
  width: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 25px 0;
  height: calc(100vh - 133px);
  margin-top: 20px;
  border-radius: 12px;
}
