.tab-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;
  background-color: white;
  border-bottom: 1px solid white;
  gap: 25px;
}

.tab {
  font-family: Lato;
  font-size: 13px;
  padding: 13px 0 14px 20px;
  cursor: pointer;
  color: black;
  background-color: white;
  border: none;
}
.tab:hover {
  font-weight: bold;
}
.active-tab {
  cursor: pointer;
  padding: 13px 0 14px 20px;
  border-radius: 8px;
  background-color: #f5f6f8;
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  padding: 13px 0 14px 20px;
  border-radius: 8px;
  background-color: #f5f6f8;
  color: #d50032;
  /*should only be as long as the tab*/
}
.active-tab svg path {
  fill: #d50032 !important;
  stroke: #d50032 !important;
}
.main-wrapper {
  display: flex;
  justify-content: space-between;
}
.side-bar {
  display: flex;
  flex-direction: column;
  width: 18%;
  padding: 10px 18px 40px;
  height: calc(100vh - 50px);
  justify-content: space-between;
}
.content-main-wrapper {
  width: 78%;
  background-color: #f9fafb;
  height: 100vh;
  padding: 0 35px;
}
.content-main-title {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  gap: 20px;
  font-family: Lato;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  text-align: left;
  color: #d50032;
  margin-top: 30px;
}
.content-main-title-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background-color: #fff;
}
.content-main-title-icon svg path {
  fill: #d50032 !important;
  stroke: #d50032 !important;
}
.header-titles h1 {
  padding: 10px 15px;
  font-family: Lato;
  font-size: 20px;
  color: #1c1d21;
}
.tabs-list-icon {
  display: flex;
  gap: 20px;
  align-items: center;
}

.logout-button {
  font-family: Lato;
  font-size: 14px;
  line-height: 1.21;
  color: #1c1d21;
  width: -moz-fit-content;
  width: fit-content;
  background: transparent;
  border: none;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0 33%;
  cursor: pointer;
  height: 46px;
  border-radius: 8px;
  /* transition: 0.5s ease; */
  font-weight: bold;
}

.logout-button:hover {
  background-color: #d40032;
  color: #ffffff;
  /* transition: 0.5s ease-out; */
}

.logout-button:hover svg path {
  fill: #ffffff;
}

.logout-button:active {
  background-color: #d50032;
  color: #ffffff;
  /* transition: 0.5s ease-out; */
}

.logout-button:active svg path {
  fill: #ffffff;
}

.questionsmark{
  margin-left: -8px;
  cursor: pointer;
}

.tooltiptxt{
  padding: 6px 10px;
  font-family: 'Lato';
}