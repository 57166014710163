.recovercontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  width: 22%;
  margin: 0 auto;
}
.recovercontainer .main {
  width: 100%;
}

.recovercontainer .form {
  width: 100%;
  margin-top: 30px;
}

.recovercontainer .main p.pera {
  font-size: 14px;
  color: #8181a5;
  font-family: "Lato";
}

.recovercontainer .main h2 {
  font-family: "Lato" !important;
  margin-top: 0px;
  margin-bottom: 15px;
  color: #1c1d21;
  margin-bottom: 0px;
  font-family: Lato;
  line-height: 1.4;
  font-size: 32px;
  font-weight: bold;
}

.recovercontainer .form label {
  font-family: "Lato";
  color: #8181a5;
  font-size: 14px;
}

.recovercontainer .form input {
  width: 89%;
  border-radius: 8px;
  border: solid 1px #f0f0f3;
  background-color: #fff;
  padding: 15px 20px 16px 20px;
  color: #111827;
  font-family: Lato;
  font-size: 14px;
}

.recovercontainer .form-buttons {
  margin-top: 50px;
}

.recovercontainer .form-buttons button {
  width: 45%;
}

/* Responsive CSS */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .recovercontainer {
    width: 90% !important;
  }

  .recovercontainer input {
    width: 85% !important;
  }

  .remember input {
    width: auto !important;
  }

  .recovercontainer .main h2 {
    font-size: 30px !important;
  }

  .recovercontainer .form-buttons {
    margin-top: 40px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .recovercontainer {
    width: 50% !important;
  }
  .recovercontainer .main h2 {
    font-size: 30px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .recovercontainer {
    width: 38% !important;
  }
  .recovercontainer .main h2 {
    font-size: 30px !important;
  }
}
