.verify-header {
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background-color: white;
  padding: 0 20px 20px;
}

.verify-header-buttons {
  display: flex;
  gap: 15px;
}
.verify-select-box {
  display: flex;
  gap: 15px;
}

.verify-container {
  width: 95%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  padding: 20px 25px 0;
  height: calc(100vh - 133px);
  margin-top: 20px;
  border-radius: 12px;
}

.verify-header span {
  font-family: Lato;
  font-size: 14px;
  /* font-weight: bold; */
  line-height: 1.21;
  color: #1c1d21;
}

/* Additional styles for the dropdown if needed */
.react-dropdown-select {
  min-width: 200px;
  /* Adjust the width as necessary */
  /* Other styles for the dropdown */
}

.verify-select-box div div {
  margin-top: 6px;
}

.verify-select-box div div div {
  margin-top: 0px;
}
