body,
html {
  height: 100%;
  margin: 0;
  background-color: white;
  /* Adjust the color to match your screenshot */
  color: black;
  font-family: Arial, sans-serif;
  /* Choose a font that matches your design */
}

.login-container {
  width: 100%;
  max-width: 400px;
  margin: auto;
  padding: 50px 20px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h2 {
  margin-bottom: 40px;
  color: white;
  /* Assuming a light text color on a dark background */
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  text-align: left;
  margin-bottom: 10px;
}

.input-group input[type="email"],
.input-group input[type="password"] {
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  /* If you want space between the inputs */
  background-color: white;
  /* Light background for input */
  border: 1px solid #777;
  /* Subtle border for inputs */
  border-radius: 4px;
  /* Optional: if you want rounded corners */
  color: black;
}

.input-group input[type="checkbox"] {
  display: none;
  /* Hide the default checkbox */
}

.input-group input[type="checkbox"] + label {
  position: relative;
  cursor: pointer;
}

.input-group input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background-color: white;
  border: 2px solid blue;
  /* Adjust the color to match your design */
  vertical-align: bottom;
}

.input-group input[type="checkbox"]:checked + label:before {
  background-color: blue;
  /* Adjust the color to match your design */
}

.input-group button {
  width: 100%;
  padding: 15px;
  border: 2px solid white;
  /* Solid border for button */
  background: none;
  /* No background for the button */
  color: white;
  text-transform: uppercase;
  /* Optional: styling the text to be uppercase */
  cursor: pointer;
  border-radius: 4px;
  /* Optional: if you want rounded corners */
  margin-top: 20px;
}

/* Hover state for button */
.input-group button:hover {
  background-color: white;
  color: black;
}

/* Focus state for inputs */
.input-group input[type="email"]:focus,
.input-group input[type="password"]:focus {
  outline: none;
  border-color: #aaa;
  /* A lighter border color when focused */
}

.login-button:hover {
  background-color: #666;
  /* Slightly lighter on hover */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.login-button {
  position: relative;
  /* Add this line */
  width: 107%;
  padding: 15px;
  background-color: #555;
  color: white;
  border: 1px solid #777;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.loading-button:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  /* Adjust as needed */
  margin-left: -10px;
  /* Adjust as needed */
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spin 0.6s linear infinite;
}

.hidden {
  visibility: hidden;
}

/* Login CSS */

.logincontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  width: 22%;
  margin: 0 auto;
}

.logincontainer .main {
  width: 100%;
}

.logincontainer .form {
  width: 100%;
}

.logincontainer .main h2 {
  font-family: "Lato" !important;
  margin-bottom: 15px;
  color: #1c1d21;
  font-family: Lato;
  font-size: 32px;
  font-weight: bold;
}

.logincontainer .main p.pera {
  font-size: 14px;
  color: #8181a5;
  font-family: "Lato";
}

.logincontainer .form {
  margin-top: 30px;
}

.logincontainer .form label {
  font-family: "Lato";
  color: #8181a5;
  font-size: 14px;
}

.logincontainer .form input {
  width: 89%;
  border-radius: 8px;
  border: solid 1px #f0f0f3;
  background-color: #fff;
  padding: 15px 20px 16px 20px;
  color: #111827;
  font-family: Lato;
  font-size: 14px;
}

form .label:nth-child(3) {
  margin-top: 12px;
}

.field {
  width: 100%;
  margin-top: 4px;
}

.remember input {
  width: auto !important;
  transform: scale(1.6);
  margin: 0px 16px 0px 0px;
  position: relative;
  top: 2px;
  left: 5px;
}

.remember {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
  gap:5px;
  margin-top: 19px;
  margin-bottom: 50px;
  font-size: 14px;
  font-weight: bold;
  width: fit-content;
  cursor: pointer;
}

.remember .check {
  font-family: "Lato";
  font-size: 14px;
  font-weight: bold;
  color: #1c1d21;
}

.forgot {
  color: #111827;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.form-buttons {
  display: flex;
  gap: 8px;
}

.logincontainer .form input::placeholder {
  color: #111827;
  font-family: Lato;
  font-size: 14px;
}
.remember .check{
  display: flex;
  justify-content: center;
  align-items: center;
   border:  solid 2px #111827;
   width: 20px;
   height: 20px;
   border-radius: 4px;
}
/* Responsive CSS */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .logincontainer {
      width: 90% !important;
  }
  
  .logincontainer input {
      width: 85% !important;
  }
  
  .remember input {
      width: auto !important;
  }
  
  .logincontainer .main h2 {
      font-size: 30px !important;
  }
  }
  
  
  @media only screen and (min-width: 768px) and (max-width: 1000px) {
  .logincontainer {
      width: 50% !important;
  }
      .logincontainer .main h2 {
      font-size: 30px !important;
  }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .logincontainer {
      width: 38% !important;
  }
      .logincontainer .main h2 {
      font-size: 30px !important;
  }
  }
  