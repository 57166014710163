/* Sign Up CSS */

.signupcontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  width: 22%;
  margin: 0 auto;
}

.signupcontainer .main {
  width: 100%;
}

.signupcontainer .form {
  width: 100%;
}

.signupcontainer .main h2 {
  font-family: "Lato" !important;
  margin-bottom: 15px;
  color: #1c1d21;
  font-family: Lato;
  font-size: 32px;
  font-weight: bold;
}

.signupcontainer .main p.pera {
  font-size: 14px;
  margin-bottom: 0px;
  color: #8181a5;
  font-family: "Lato";
}

.signupcontainer .form {
  margin-top: 30px;
}

.signupcontainer .form label {
  font-family: "Lato";
  color: #8181a5;
  font-size: 14px;
}

.signupcontainer .form input {
  width: 89%;
  border-radius: 8px;
  border: solid 1px #f0f0f3;
  background-color: #fff;
  padding: 15px 20px 16px 20px;
  color: #111827;
  font-family: Lato;
  font-size: 14px;
}

form .label:nth-child(3) {
  margin-top: 12px;
}

.field {
  width: 100%;
  margin-top: 4px;
}

.remember input {
  width: auto !important;
  transform: scale(1.6);
  margin: 0px 16px 0px 0px;
  position: relative;
  top: 2px;
  left: 5px;
}

.remember {
  display: flex;
  justify-content: space-between;
  margin-top: 33px;
  margin-bottom: 50px;
}

.remember .check {
  font-family: "Lato";
  font-size: 14px;
  font-weight: bold;
  color: #1c1d21;
}

.forgot {
  color: #111827;
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.form-buttons {
  display: flex;
  gap: 8px;
}

.signupcontainer .form input::placeholder {
  color: #111827;
  font-family: Lato;
  font-size: 14px;
}

.signupcontainer .label {
  margin-top: 13px;
}


/* Responsive CSS */

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .signupcontainer {
      width: 90% !important;
  }
  
  .signupcontainer input {
      width: 85% !important;
  }
  
  .remember input {
      width: auto !important;
  }
  
  .signupcontainer .main h2 {
      font-size: 30px !important;
  }
  }
  
  
  @media only screen and (min-width: 768px) and (max-width: 1000px) {
  .signupcontainer {
      width: 50% !important;
  }
      .signupcontainer .main h2 {
      font-size: 30px !important;
  }
  }
  
  @media only screen and (min-width: 1024px) and (max-width: 1300px) {
  .signupcontainer {
      width: 38% !important;
  }
      .signupcontainer .main h2 {
      font-size: 30px !important;
  }
  }
  