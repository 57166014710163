.buttons {
  width: 100%;
  height: 47px;
  border-radius: 8px;
  font-family: "Lato";
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 1px;
  cursor: pointer;
  /* transition: 0.2s linear; */
}

.custom-button-primary {
  background-color: #d50032;
  border: solid 1px#d50032;
  color: #ffffff;
}
.custom-button-primary:hover {
  background-color: #9f0025;
  border: solid 1px#9f0025;
  /* transition: 0.5s ease-out; */
}
.custom-button-primary-disabled {
  border: solid 1px transparent;
  pointer-events: none;
  cursor: none;
}
.custom-button-outline {
  background-color: transparent;
  border: solid 1px #111827;
  color: #111827;
}
.custom-button-outline:hover {
  border-color: #11182770;
  color: #11182770;
}
